import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { REDUX_RESET } from '@aclito/shared/util/constants';
import { AppState } from '@aclito/shared/redux/store';

export const REDUX_TOKEN = 'pushToken';
export const REDUX_LINK = 'link';
const SlicePushToken = createSlice({
  name: REDUX_TOKEN,
  initialState: '',
  reducers: {
    updatePushToken: (_state, action: PayloadAction<string>) => action.payload,
    [REDUX_RESET + REDUX_TOKEN]: () => '',
  },
});
const SliceLink = createSlice({
  name: REDUX_LINK,
  initialState: '',
  reducers: {
    updateLink: (_state, action: PayloadAction<string | undefined>) =>
      action.payload ?? '',
    [REDUX_RESET + REDUX_TOKEN]: () => '',
  },
});
export const mobileActions = {
  ...SlicePushToken.actions,
  ...SliceLink.actions,
};
export const mobileReducers = {
  [REDUX_TOKEN]: SlicePushToken.reducer,
  [REDUX_LINK]: SliceLink.reducer,
};
export const mobileSelectors = {
  pushToken: (state: AppState) => state.pushToken,
  link: (state: AppState) => state.link,
};
