import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';

import { VAPID_KEY } from './constants';

const firebaseConfig = {
  apiKey: 'AIzaSyBW2osQQT2A356oCvy_hI6D7firVcNaj5c',
  authDomain: 'aclito-eee333.firebaseapp.com',
  projectId: 'aclito-eee333',
  storageBucket: 'aclito-eee333.appspot.com',
  messagingSenderId: '1064553150682',
  appId: '1:1064553150682:web:5ee7d6eedc96e88cc0cde3',
  measurementId: 'G-NZBCJHC6LV',
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = (await isSupported())
  ? getMessaging(firebaseApp)
  : null;

export const getFirebaseToken = async () => {
  if (!messaging) {
    console.log('Messaging firebase token not supported');
    return null;
  }
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: VAPID_KEY,
    });
    if (currentToken) {
      return currentToken;
    }
    console.log(
      'No registration token available. Request permission to generate one.',
    );
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};
